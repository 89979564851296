.hero {
  background: url("/images/anglo-indian-flag.png");
  background-size: cover;
  background-position: center; 
  /* background-attachment: fixed; 
  background-repeat: no-repeat; */
}

.tabImage:hover {
  cursor: pointer;
}

.background {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}